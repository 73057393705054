import Vue from 'vue';

import axios from 'axios';

import md5 from 'js-md5';

let publicKey = 'bosihuiwen@!bj';
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'https://apitest.gp519.com'; //'https://api.gp519.com'
axios.defaults.baseURL = 'https://api.gp519.com';
// axios.defaults.baseURL = 'https://localhost:5001';
// axios.defaults.baseURL = 'http://192.168.0.130:9022';
export default {
    get (url, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    params: params
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    ValidSignStr (dictionary) {
        let dictionary1 = JSON.parse(JSON.stringify(dictionary));
        for (var key in dictionary1) {
            dictionary1["'" + key.toLowerCase() + "'"] = dictionary1[key];
            delete dictionary1[key];
        }
        var sortKeys = Object.keys(dictionary1).sort();
        var str = '';
        // var i = 0;
        for (var item in sortKeys) {
            var bl = false;
            var _Keyvalue = dictionary1[sortKeys[item]];
            if (typeof dictionary1[sortKeys[item]] != 'number') {
                if (_Keyvalue instanceof Object == true) {
                    bl = false;
                } else {
                    bl = dictionary1[sortKeys[item]].length > 0 ? true : false;
                }
            } else {
                bl = true;
            }
            if (bl) {
                str += sortKeys[item] + '=' + dictionary1[sortKeys[item]] + '&';
                str = str.replace(/'/g, '');
            }
        }
        if (str != '') {
            str = str.substr(0, str.length - 1);
        }
        var Sign = md5(str.toLowerCase());
        return Sign;
    },

    post (url, data) {
        let PostData = data;
        delete PostData.Sign;
        Vue.set(PostData, 'AppKey', publicKey);
        let Sign = this.ValidSignStr(PostData);
        Vue.set(PostData, 'Sign', Sign);
        delete PostData.AppKey;
        return new Promise((resolve, reject) => {
            axios.post(url, PostData).then(
                response => {
                    resolve(response);
                },
                err => {
                    reject(err);
                }
            );
        });
    }
};
