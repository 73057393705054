<template>
  <div>
    <div class="content EC_width marginTop40">
      <div class="gl_title_bar">
        <div class="gl_tb_left">
          <span class="normaltitle">项目动态</span>
          <span class="marginLeft10">{{projectname}}</span>
        </div>
        <div class="gl_tb_right">
          <div class="pagepre">&lt;</div>
          <div class="pagenext">&gt;</div>
        </div>
      </div>
      <div class="display_between marginTop40"
           v-for="(item,index) in uselist"
           :key="index">
        <div class="display_between "
             :class="{marginLeft40:indexn==1}"
             v-for="(itemn,indexn) in item"
             :key="indexn"
             @click="proDynamLink(itemn)"
             style="cursor: pointer">
          <img :src="itemn.image"
               width="177"
               height="121">
          <div class="marginLeft20">
            <p class="fontBold fontsize16">{{itemn.title}}</p>
            <p class="marginTop20"
               style="width:391px">
              {{ellipsis(itemn.contents,80)}}
            </p>
            <p class="marginTop10 grayColor">{{formatTime(itemn.createtime,'YYYY-MM-DD')}}</p>
          </div>
        </div>
      </div>

      <!-- <pagination></pagination> -->
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import format from '@/assets/unit/unit.js'

export default {
  name: 'enterpriseList',
  data () {
    return {
      apiData: {},
      uselist: [],
      // 颜色集合
      colorSet: [],
      prodynatextlist: [],
      projectname: '',
    }
  },
  created () {
    // this.getData()
    this.GetSpecialProject()
  },
  methods: {
    GetSpecialProject () {
      this.httphelper
        .post(
          '/api/CrduApi/GetCrduSpecialDataItemDetailsPageLists',
          {
            specialInfoID: Number(this.$route.query.SpecialID),
            sD_Id: 0,
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.SpecialProject = res.data.data

            this.SpecialProject.forEach((item) => {
              if (item.category == '7') {
                // 项目动态
                this.prodynatextlist.push(item)
              } else if (item.category == '6') {
                // 颜色设置
                this.colorSet = JSON.parse(item.contents)
              } else if (item.category == '12') {
                this.projectname = item.title
                document.title = this.projectname
              }
            })
            this.uselist = lodash.chunk(this.prodynatextlist, 2)
            // console.log(this.uselist)
          }
        })
    },
    proDynamLink (info) {
      console.log(info, 'tiaoz')
      return
      let urlstr = info.address
      let param = urlstr.substr(
        urlstr.indexOf('?'),
        urlstr.length - urlstr.indexOf('?')
      )
      if (urlstr.search('specialRecomme') > -1) {
        const { href } = this.$router.resolve({
          path: '/home/specialRecomme?',
          query: {
            SpecialID: this.$route.query.SpecialID,
            id: info.id,
          },
        })
        window.open(href, '_blank')
      } else if (urlstr.search('specialproject/projectdetail') > -1) {
        const { href } = this.$router.resolve({
          path: '/enterprise/enterpriseDetail?' + param,
        })
        window.open(href, '_blank')
      } else {
        window.open(info.address, '_blank')
      }
    },
    formatTime (datatime, timeformat) {
      return format(datatime, timeformat)
    },
  },
  watch: {
    $route () {
      document.title = this.projectname
    },
  },
}
</script>

<style scoped>
.content {
    flex: 1;
    padding-bottom: 40px;
}

.pagepre,
.pagenext {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    color: #ccc;
    text-align: center;
    line-height: 30px;
    border-radius: 30px;
    cursor: pointer;
}

.pagepre:hover,
.pagenext:hover {
    background: #0b8485;
    color: #fff;
}
</style>
