<template>
  <div id="app">
    <header_index v-show="$route.meta.navShow"></header_index>
    <router-view v-if="isRouterAlive"></router-view>
    <footer_index v-show="$route.meta.navShow"></footer_index>
  </div>
</template>

<script>
import header_index from './views/home/header_index';
import footer_index from './views/home/footer_index';
export default {
  name: 'App',
  // 提供一个刷新的依赖
  provide () {
    return {
      reload: this.reload
    };
  },
  data () {
    return {
      isRouterAlive: true
    };
  },
  components: {
    header_index,
    footer_index
  },
  mounted () {
    // 禁用右键
    document.oncontextmenu = function () {
      return false;
    };

    // 创建cnzz统计js
    const script = document.createElement('script');
    script.src = 'https://s4.cnzz.com/z_stat.php?id=1279993300&web_id=1279993300';
    script.language = 'JavaScript';
    document.body.appendChild(script);
    // if(this.isMobile())
    // {
    //   window.location.href="https://h5.gp519.com"
    // }
  },
  //   computed: {
  //     key () {
  //       return this.$route.fullPath
  //     }
  //   },
  methods: {
    reload () {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  }
};
</script>

<style>
@import 'assets/css/comm.css';
@import 'assets/css/globe.css';
@import 'assets/css/swiperSet.css';
#app {
    height: 100%;
}
.imgBanner {
    width: 100%;
    cursor: pointer;
}
.imgtran {
    transform: scale(1.03);
    cursor: pointer;
}
a {
    cursor: pointer;
}
</style>
