<template>
  <div class="content">
    <div class="main-container container"
         id="main-container">
      <div class="row rows">
        <!-- <div class="col-lg-12 btH2">
          <h2>
            医周 <p>（
            {{typeName}}
            ）</p>
          </h2>
        </div> -->

        <div class="col-lg-12 blog__content mb-30">
          <!-- 新闻列表 -->
          <div class="section">

            <div class="entry post-list"
                 v-for="(item, index) in yzNewList"
                 :key="index">
              <div class="entry__body post-list__body">
                <a :title="item.title"
                   href="javascript:void(0)"
                   @click="topost(item)">
                  <img :src="item.slideShow"
                       :alt="item.title"></a>
                <div class="entry__header">
                  <!-- <a href="/Content/Article/listbytag/tagid/575.do" target="_blank" class="entry__meta-category">合理用药</a> -->
                  <span class="entry__meta-category"
                        v-for="(qitem, index) in (item.labelName || '').split(',')"
                        :key="index">
                    {{ qitem }}
                  </span>

                </div>
                <div class="entry__excerpt">
                  <h2 class="entry__title">
                    <a :title="item.title"
                       href="javascript:void(0)"
                       @click="topost(item)">{{item.title}}</a>
                  </h2>
                  <p>{{item.abstract}}</p>
                  <ul class="entry__meta"
                      style="text-align:right;">
                    <li class="entry__meta-date">
                      <i class="ui-date"></i>
                      {{ formatTime(item.publishtime, 'YYYY/MM/DD') }}
                    </li>
                    <li class="entry__meta-comments">
                      <i class="fa fa-eye"></i>
                      <a href="#">{{ item.clickVolume }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <p class="gengduo"
             data-index="1"
             ref="moreload1"
             @click="more()">
            <a>+ 加载更多</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script> 
import format from '@/assets/unit/unit.js';
export default {
  name: 'home',
  data () {
    return {
      typeName: '',
      typeClass: 1,
      yzNewList: []
    };
  },
  created () {
    if (this.$route.query.typeName != '') {
      this.typeName = this.$route.query.typeName
      this.typeClass = this.$route.query.typeClass
    }
    //首页资讯
    this.tagDatahot();
  },
  methods: {
    tagDatahot () {
      let _this = this
      _this.yzNewList = [];
      _this.httphelper
        .post('/api/CrduApi/GetDbCrduNewInfoList', {
          keyword: this.typeClass == 1 ? this.typeName : '',
          lablename: this.typeClass == 1 ? '' : this.typeName,
          column_Type: 0,
          pageIndex: 1,
          pageSize: 10
        })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data != null && res.data.data.length > 0) {
              _this.yzNewList = res.data.data;
            }
          }
        });
    },
    more () {
      let _this = this
      _this.$refs.moreload1.dataset.index = parseInt(_this.$refs.moreload1.dataset.index) + 1
      _this.httphelper
        .post('/api/CrduApi/GetDbCrduNewInfoList', {
          keyword: '',
          lablename: this.typeName,
          column_Type: 0,
          pageIndex: parseInt(_this.$refs.moreload1.dataset.index),
          pageSize: 10
        })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data != null && res.data.data.length > 0) {
              res.data.data.forEach(item => {
                _this.yzNewList.push(item);
              });
            }
          }
        });
    },
    formatTime (datatime, timeformat) {
      return format(datatime, timeformat);
    },
    topost (item) {
      if (item.address != null && item.address != '') {
        let _this = this;
        _this.httphelper
          .post('/api/CrduApi/GetCrduNewInfoById', {
            column_Type: item.cid,
            id: item.id,
            ParentID:0,
            pageIndex: 0,
            pageSize: 0
          })
          .then(res => {
            window.open(item.address, '_blank')
          });
      } else {
        this.$router.push({
          name: 'post',
          query: {
            id: item.id,
            cid: item.cid
          }
        })
      }
    }
  }
};
</script>