import VueRouter from 'vue-router';

/** crdu  */
import crduhome from '../views/home/crduhome';
import content from '../views/news/content';
import casecontent from '../views/cases/content';
import meeting from '../views/meeting';
import post from '../views/post';
import post2 from '../views/post/index2';
import list from '../views/list';
// 企业专题
import enterprise from '../views/enterprise/index';
import qyhome from '../views/enterprise/home';
import enterpriseList from '../views/enterprise/enterpriseList';
import enterpriseList2 from '../views/enterprise/enterpriseList2';
import enterpriseDetail from '../views/enterprise/enterpriseDetail';


const routes = [
    { path: '', redirect: '/crduhome' },

    {
        path: '/crduhome',
        name: 'crduhome',
        component: crduhome,
        meta: { navShow: true, title: '临床合理用药网' }
    },
    {
        path: '/content',
        name: 'content',
        component: content,
        meta: { navShow: true, title: '医周' }
    },
    {
        path: '/casecontent',
        name: 'casecontent',
        component: casecontent,
        meta: { navShow: true, title: '病例' }
    },
    {
        path: '/meeting',
        name: 'meeting',
        component: meeting,
        meta: { navShow: true, title: '合理用药大会' }
    },
    {
        path: '/post',
        name: 'post',
        component: post,
        meta: { navShow: true, title: '文章详情' }
    },
    {
        path: '/post2',
        name: 'post2',
        component: post2,
        meta: { navShow: true, title: '文章详情' }
    },
    {
        path: '/list',
        name: 'list',
        component: list,
        meta: { navShow: true, title: '资讯列表' }
    }, {
        path: '/enterprise',
        name: 'enterprise',
        component: enterprise,
        redirect: '/enterprise/qyhome',
        meta: { navShow: true, breadcrumbTitle: '专题首页' },
        children: [
            {
                path: 'qyhome',
                name: 'qyhome',
                component: qyhome,
                meta: {
                    navShow: false,
                    activeNum: 'qy0',
                    title: '专题首页'
                }
            },
            {
                path: 'enterpriseList',
                name: 'enterpriseList',
                component: enterpriseList,
                meta: {
                    activeNum: 'qy1',
                    title: '专题列表页',
                    breadcrumbTitle: '专题列表页'
                }
            },
            {
                path: 'enterpriseList2',
                name: 'enterpriseList2',
                component: enterpriseList2,
                meta: {
                    activeNum: 'qy2',
                    title: '专题列表页',
                    breadcrumbTitle: '专题列表页'
                }
            },
            {
                path: 'enterpriseDetail',
                name: 'enterpriseDetail',
                component: enterpriseDetail,
                meta: {
                    activeNum: 'qy3',
                    title: '专题详情页',
                    breadcrumbTitle: '专题详情页'
                }
            }
        ]
    },
];
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err);
};
//replace
const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace (to) {
    return VueRouterReplace.call(this, to).catch(err => err);
};

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to) {
        if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return { x: 0, y: 0 };
        }
    }
});
export default router;
