<template>
  <div>
    <!-- <breadcrumbPage></breadcrumbPage> -->

    <div class="EC_width content">
      <p class="fontBold text_center marginTop40"
         style="font-size: 26px;">{{title}}</p>
      <p class="text_center marginTop40">
        <!-- <span class="marginRight40"><img src="/image/qiye/shizhong.png" class="imgvertical-2 marginRight5">{{apiData.date}}</span>
                <span class="marginRight40">来源：{{apiData.source}}</span>
                <span>点击量：{{apiData.clickNum}}</span> -->
      </p>
      <div class="border_bottom_18 marginBottom30"></div>
      <!-- <p :class="{mainText:item.sign=='mainText',maintitle:item.sign=='maintitle',mainImg:item.sign=='mainImg'}" v-for="(item,index) in apiData.content" :key="'0'+index">
                <img :src="item.main" v-if="item.sign=='mainImg'">
                <span v-if="item.sign!='mainImg'">{{item.main}}</span>
            </p> -->
      <p v-html="details">

      </p>
    </div>
  </div>
</template>

<script>
// import breadcrumbPage from '../home/breadcrumbPage'
export default {
  name: 'enterpriseDetail',
  data () {
    return {
      apiData: {},
      title: '',
      details: '',
    }
  },
  created () {
    this.getData()
    this.GetProjectNewsDetailInfoByID()
  },
  methods: {
    getData () {
      var that = this
      fetch('/data.json')
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          that.apiData = data.enterpriseDetail
        })
        .catch((erro) => {
          console.log(erro)
        })
    },
    GetProjectNewsDetailInfoByID () {
      this.httphelper
        .post('/api/CrduApi/GetCrduSpecialDataItemDetailsPageLists', {
          ID: Number(this.$route.query.id),
          S_ID: Number(this.$route.query.SpecialID),
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.title = res.data.data[0].title
            this.details = res.data.data[0].contents
          }
        })
    },
  },
  // components: {
  //     breadcrumbPage,
  // },
}
</script>

<style scoped>
.content {
    flex: 1;
    padding-bottom: 40px;
}

.maintitle {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
}

.mainText {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 32px;
    margin-bottom: 44px;
}

.mainImg {
    margin-bottom: 44px;
}
</style>
