/**时间格式化 */
function format(date, format) {
    if (!date) {
        return '';
    }
    return formatter(date, format);
    // let d = new Date(date)

    // // 年
    // if (/YYYY/.test(format)) {
    //     format = format.replace(/YYYY/, d.getFullYear())
    // }

    // // 月份
    // let month = d.getMonth() + 1

    // if (/MM/.test(format)) {
    //     let monthStr = month < 10 ? '0' + month : month
    //     format = format.replace(/MM/, monthStr)
    // } else if (/M/.test(format)) {
    //     format = format.replace(/M/, month)
    // }
    // // 日期
    // let dates = d.getDate()
    // if (/DD/.test(format)) {
    //     let dateStr = dates < 10 ? '0' + dates : dates
    //     format = format.replace(/DD/, dateStr)
    // } else if (/D/.test(format)) {
    //     format = format.replace(/D/, dates)
    // }
    // // 小时
    // let hours = d.getHours()
    // if (/HH/.test(format)) {
    //     console.log(hours)
    //     let hoursStr = hours < 10 ? '0' + hours : hours
    //     format = format.replace(/HH/, hoursStr)
    // } else if (/H/.test(format)) {
    //     format = format.replace(/H/, hours)
    // } else if (/hh/.test(format)) {
    //     let hoursMin = hours > 12 ? hours - 12 : hours
    //     let hoursStr = hoursMin < 10 ? '0' + hoursMin : hoursMin
    //     format = format.replace(/hh/, hoursStr)
    // } else if (/h/.test(format)) {
    //     let hoursMin = hours > 12 ? hours - 12 : hours
    //     format = format.replace(/h/, hoursMin)
    // }
    // // 分
    // let minutes = d.getMinutes()
    // if (/mm/.test(format)) {
    //     let minutesStr = minutes < 10 ? '0' + minutes : minutes
    //     format = format.replace(/mm/, minutesStr)
    // } else if (/m/.test(format)) {
    //     format = format.replace(/m/, minutes)
    // }
    // // 秒
    // let seconds = d.getSeconds()
    // if (/ss/.test(format)) {
    //     let secondsStr = seconds < 10 ? '0' + seconds : seconds
    //     format = format.replace(/ss/, secondsStr)
    // } else if (/s/.test(format)) {
    //     format = format.replace(/s/, seconds)
    // }
    // return format
}

function formatter(time, pattern = '') {
    var EnMMList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var format = time.replace(/-/g, '/'); //把“-”，替换成‘/’
    var date = new Date(format),
        y = date.getFullYear(),
        m = (date.getMonth() + 1).toString().padStart(2, 0),
        d = date
            .getDate()
            .toString()
            .padStart(2, 0),
        h = date
            .getHours()
            .toString()
            .padStart(2, 0),
        mi = date
            .getMinutes()
            .toString()
            .padStart(2, 0),
        se = date
            .getSeconds()
            .toString()
            .padStart(2, 0);
    if (pattern == 'YYYY/MM/DD') {
        return `${y}/${m}/${d}`;
    } else if (pattern == 'YYYY-MM-DD') {
        return `${y}-${m}-${d}`;
    } else if (pattern == 'en') {
        var mm = Number(m - 1);
        // alert(mm);
        // console.log(EnMMList[mm]);
        return EnMMList[mm] + '. ' + d + ',' + y; //`${y}-${m}-${d} ${h}:${mi}:${se}`;
    } else {
        return `${y}-${m}-${d} ${h}:${mi}:${se}`;
    }
}

export default format;
