function ellipsis(value, length) {
    if (value) {
        if (value != '' && value.length > length) {
            return value.substring(0, length + 1) + '...'
        }
    }
    return value
}

export default ellipsis
