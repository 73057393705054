<template>
  <div>
    <!-- 头部 -->
    <nav class="navbar navbar-expand-lg navbar-light rounded">
      <div class="container">
        <a class="navbar-brand"
           href="crduhome">
          <img src="https://cgp-resource.oss-cn-beijing.aliyuncs.com/Images/News/20220214/0105a0e9718a1427210dede7d090d40c.png">
        </a>
        <div class="collapse navbar-collapse"
             :class="isShow?'show':''"
             id="navbarsExample09">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item"
                :class="getrouter()==1?'active':''">
              <a class="nav-link"
                 href="crduhome">首页</a>
            </li>
            <li class="nav-item nav__dropdown "
                :class="getrouter()==2?'active':''">
              <a class="nav-link"
                 href="content?cid=15">医周 <i class="fa fa-caret-down"
                   aria-hidden="true"></i></a>
              <ul class="nav__dropdown-menu">
                <div class="nav__dropdown-menu_div">
                  <li><a href="content?cid=11">政讯</a></li>
                  <li><a href="content?cid=12">研究</a></li>
                  <li><a href="content?cid=13">医说</a></li>
                  <li><a href="content?cid=14">药说</a></li>
                  <li><a href="content?cid=15">微精选</a></li>
                </div>
              </ul>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)" id="diagnosis">诊疗库</a>
            </li> -->
            <li class="nav-item"
                :class="getrouter()==3?'active':''">
              <a class="nav-link"
                 href="casecontent">病案室</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="https://crdu.cn/#">医药课堂</a>
            </li> -->
            <li class="nav-item"
                :class="getrouter()==4?'active':''">
              <a class="nav-link"
                 href="meeting">合理用药大会</a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 href="http://www.lchlyyzz.net/">《临床合理用药》杂志</a>
            </li>

          </ul>
        </div>
        <!-- 导航右侧部分 -->
        <div class="nav__right nav--align-right d-lg-flex"
             style="height:50px">
          <!-- Socials -->
          <div class="nav__right-item socials nav__socials d-none d-lg-flex">
            <a class="social social-twitter social--nobase"
               target="_blank"
               aria-label="twitter">
              <i class="fa fa-weixin"></i>
            </a>
            <a class="social social-google social--nobase"
               target="_blank"
               aria-label="google">
              <i class="fa fa-qq"></i>
            </a>
            <a class="social social-youtube social--nobase"
               target="_blank"
               aria-label="youtube">
              <i class="fa fa-envelope"></i>
            </a>
          </div>
          <!-- Search -->
          <div class="nav__right-item nav__search">
            <a href="javascript:void(0)"
               class="nav__search-trigger">
              <i class="el-icon-search nav__search-trigger-icon"
                 v-show="isSearch==false"
                 @click="isSearch=true"></i>
              <i class="el-icon-close nav__search-trigger-icon"
                 v-show="isSearch==true"
                 @click="isSearch=false"></i>
            </a>
            <div class="nav__search-box"
                 v-show="isSearch==true">
              <!-- <form class="nav__search-form"> -->
              <div class="nav__search-form">
                <input type="text"
                       placeholder="Search an article"
                       class="nav__search-input"
                       v-model="keyword"
                       name="keyword">
                <button type="button"
                        class="search-button btn btn-lg btn-color btn-button"
                        @click="toSearch()">
                  <i class="el-icon-search nav__search-icon"></i>
                </button>
              </div>
              <!-- </form> -->
            </div>
          </div>
          <!-- 登录/注册 -->
          <div class="dlzc"
               v-show="tel==''">
            <i class="fa fa-user"></i> <a href="https://gp519.com/loginIndex/login?callback=https%3A%2F%2Fcrdu.cn%2Fcrduhome&type=2">登录</a> | <a href="https://gp519.com/loginIndex/loginRegister?callback=https%3A%2F%2Fcrdu.cn%2Fcrduhome&type=2">注册</a>
          </div>
          <el-popover placement="bottom"
                      trigger="click">
            <p style="cursor: pointer;text-align: center;margin-bottom:0px"
               @click="toTuichu()">退出登录</p>
            <div class="dlzc"
                 slot="reference"
                 style="margin-top:1px"
                 v-if="tel!=''">
              <i class="el-icon-user"></i>
              <span style="margin-left:2px;cursor: pointer;">{{tel.substr(0,3) + '****' +tel.substr(7)}}</span>
            </div>
          </el-popover>
        </div>

        <!-- 导航右侧部分 end -->
        <button class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarsExample09"
                aria-controls="navbarsExample09"
                aria-expanded="false"
                @click="isShow=!isShow"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
    <div class="header">
      <div class="container">
        <div class="flex-parent align-items-center">
          <!-- banner图 -->
          <div class="text-center banner"
               style="margin-top: 25px"
               v-if="topAdvert!=null && topAdvert.length>0">
            <img v-for="(item, index) in topAdvert.slice(0,1)"
                 :key="index"
                 style="cursor: pointer;"
                 @click="toUrl(item.url)"
                 :src="item.resUrl"
                 :title="item.title">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'header_index',
  data () {
    return {
      topAdvert: null, //顶部广告
      isSearch: false,
      keyword: '',
      tel: '',
      isShow: false
    };
  },
  created () { //获取广告位数据
    this.GetRotationInfo();
    this.getrouter()
    let token = this.$route.query.token
    if (token && token != '') {
      this.httphelper
        .post('/api/LoginApi/CheckToken', {
          tokenKey: token,
        })
        .then(res => {
          if (res.data.code == 200) {
            this.tel = res.data.data.mobile
            localStorage.setItem('tel', this.tel);
          } else {
            localStorage.clear();
          }
          let curl = this.delUrlData('token');
          window.location.href = curl;
        });
    } else {
      if (localStorage.getItem('tel') != '' && localStorage.getItem('tel') != 'undefined' && localStorage.getItem('tel') != null) {
        this.tel = localStorage.getItem('tel')
      }
    }
  },
  mounted () {
  },
  methods: {
    /** 获取轮播数据*/
    GetRotationInfo () {
      let _this = this;
      _this.httphelper
        .post('/api/CrduApi/GetRotationInfo', {
          type: 1,
          ruselocation: 1,
          source: 1
        })
        .then(res => {
          if (res.data.code == 200) {
            _this.topAdvert = res.data.data || null;
          }
        });
    },
    getrouter () {
      if (this.$route.name == 'crduhome') {
        return 1
      } else if (this.$route.name == 'content') {
        let cid = this.$route.query.cid
        if (cid == 6 || cid == 7 || cid == 8 || cid == 9) return 1
        else return 2
      } else if (this.$route.name == 'casecontent') {
        return 3
      } else if (this.$route.name == 'meeting') {
        return 4
      }
    },
    toSearch () {
      this.$router.push({
        name: 'list',
        query: {
          typeName: this.keyword,
          typeClass: 1
        }
      })
    },
    delUrlData (name) {
      var url = window.location.href;
      var urlparts = url.split('?');
      if (urlparts.length >= 2) {
        //参数名前缀
        var prefix = encodeURIComponent(name) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //循环查找匹配参数
        for (var i = pars.length; i-- > 0;) {
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            //存在则删除
            pars.splice(i, 1);
          }
        }
        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      }
      return url;
    },
    toTuichu () {
      localStorage.clear();
      this.$router.go(0);
    },
    toUrl (url) {
      window.open(url, '_blank');
    }
  }
};
</script>
 <style scoped>
.nav__search-box {
    display: block;
    /* right: 50%;
    margin-right: -600px; */
}
</style>