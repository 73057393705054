<template>
  <div>
    <div class="content EC_width">
      <div class="gl_title_bar marginTop40 marginBottom20"
           id="aboutus">
        <div class="gl_tb_left">
          <span class="normaltitle">关于我们</span>
          <a href="javascript:;"
             v-for="(item, index) in aboutusnaviga"
             :key="index"
             :class="{ gl_pad_btn_active: aboutCurIndex == index }"
             @click="aboutTag(index)"
             class="gl_pad_btn marginLeft10">{{ item.title }}</a>
          <!-- <a href="javascript:;" :class="{gl_pad_btn_active:aboutCurIndex==1}" @click="aboutTag(1)" class="gl_pad_btn marginLeft10">组织机构</a>
                    <a href="javascript:;" :class="{gl_pad_btn_active:aboutCurIndex==2}" @click="aboutTag(2)" class="gl_pad_btn marginLeft10">学术专家</a> -->
        </div>
        <div class="gl_tb_right">
          <!-- <a href="javascript:;" class="gl_tb_right_more"><img src="/image/index/moreimg.png" class="gltb_moreimg">更多</a> -->
        </div>
      </div>
      <div v-if="aboutCurIndex == 0">
        <div class="display_between">
          <!-- <img :src="apiData.about0.img"> -->
          <img :src="aboutusinfo.image"
               width="401"
               height="247"
               style="border-radius: 20px 0 0 0" />
          <div class="flex1 marginLeft40">
            <p class="fontBold fontsize18">{{ aboutusinfo.title }}</p>
            <p class="marginTop20"
               style="line-height: 28px;">{{ aboutusinfo.contents }}</p>
          </div>
        </div>
      </div>
      <div v-if="aboutCurIndex == 1">
        <div class="display_flex marginBottom10"
             v-if="aboutusorga.length > 0">
          <div class="blockTou">{{ aboutusorga }}<img src="/image/qiye/youjiao.png"
                 class="marginLeft10" /></div>
          <div class="blockNormal marginLeft20"
               v-for="(item, index) in aboutusorglista"
               :key="index">
            <span v-if="item.image"><img :src="item.image"
                   class="img cur"
                   alt=""
                   width="240"
                   height="66" /></span>

            <span v-else>{{ item.title }}</span>
          </div>
        </div>
        <div class="display_flex marginBottom10"
             v-if="aboutusorgb.length > 0">
          <div class="blockTou">{{ aboutusorgb }}<img src="/image/qiye/youjiao.png"
                 class="marginLeft10" /></div>
          <div class="blockNormal marginLeft20"
               v-for="(item, index) in aboutusorglistb"
               :key="index">
            <span v-if="item.image"><img :src="item.image"
                   class="img cur"
                   alt=""
                   width="240"
                   height="66" /></span>

            <span v-else>{{ item.title }}</span>
          </div>
        </div>
        <div class="display_flex marginBottom10"
             v-if="aboutusorgc.length > 0">
          <div class="blockTou">{{ aboutusorgc }}<img src="/image/qiye/youjiao.png"
                 class="marginLeft10" /></div>
          <div class="blockNormal marginLeft20"
               v-for="(item, index) in aboutusorglistc"
               :key="index">
            <span v-if="item.image"><img :src="item.image"
                   class="img cur"
                   alt=""
                   width="240"
                   height="66" /></span>

            <span v-else>{{ item.title }}</span>
          </div>
        </div>
        <div class="display_flex marginBottom10"
             v-if="aboutusorgd.length > 0">
          <div class="blockTou">{{ aboutusorgd }}<img src="/image/qiye/youjiao.png"
                 class="marginLeft10" /></div>
          <div class="blockNormal marginLeft20"
               v-for="(item, index) in aboutusorglistd"
               :key="index">
            <span v-if="item.image"><img :src="item.image"
                   class="img cur"
                   alt=""
                   width="240"
                   height="66" /></span>

            <span v-else>{{ item.title }}</span>
          </div>
        </div>
        <div class="display_flex marginBottom10"
             v-if="aboutusorge.length > 0">
          <div class="blockTou">{{ aboutusorge }}<img src="/image/qiye/youjiao.png"
                 class="marginLeft10" /></div>
          <div class="blockNormal marginLeft20"
               v-for="(item, index) in aboutusorgliste"
               :key="index">
            <span v-if="item.image"><img :src="item.image"
                   class="img cur"
                   alt=""
                   width="240"
                   height="66" /></span>

            <span v-else>{{ item.title }}</span>
          </div>
        </div>
      </div>
      <div v-if="aboutCurIndex == 2">
        <div class="display_between marginBottom20"
             v-for="(item, index) in aboutlist"
             :key="'0' + index">
          <div class="blocktou2 display_flex display_center_ud"
               v-for="(itemn, indexn) in item"
               :key="'0' + index + indexn">
            <img :src="itemn.image"
                 width="94"
                 height="94" />
            <div class="marginLeft30">
              <p class="fontsize20 fontBold marginBottom10">{{ itemn.author }}</p>
              <p style="padding-right: 20px;">{{ itemn.contents }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="gl_title_bar marginTop40 marginBottom20"
           id="dynamics">
        <div class="gl_tb_left">
          <span class="normaltitle">项目动态</span>
          <span class="grayColor marginLeft20">{{ aboutusinfo.title }}</span>
        </div>
        <div class="gl_tb_right"
             @click="navigation(2)">
          <a href="javascript:;"
             class="gl_tb_right_more"><img src="/image/index/moreimg.png"
                 class="gltb_moreimg" />更多</a>
        </div>
      </div>
      <div class="display_flex">
        <div class="leftText">
          <swiper :options="swiperOption3"
                  ref="mySwiper_info"
                  v-if="prodynatextlist.length">
            <swiper-slide v-for="(item, index) in prodynatextlist"
                          :key="index">
              <div class="introduct"
                   @click="proDynamLink(item)">
                <div class="introTitle">
                  {{ item.title }}
                </div>
                <div class="introText">
                  {{ ellipsis(item.contents, 230) }}
                </div>
                <div class="moreintro">
                  查看详情
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination fiexdrig"
                 slot="pagination"></div>
          </swiper>
        </div>
        <div class="flex1 display_center_ud">
          <!-- <img src="/image/qiye/youceimg.png"> -->
          <img :src="projectimg"
               width="547"
               height="302" />
        </div>
      </div>
      <div class="gl_title_bar marginTop40 marginBottom20"
           id="audiovisual">
        <div class="gl_tb_left">
          <span class="normaltitle">精彩视听</span>
          <span class="grayColor marginLeft20">{{ aboutusinfo.title }}</span>
        </div>
        <div class="gl_tb_right"
             @click="navigation(3)">
          <a href="javascript:;"
             class="gl_tb_right_more"><img src="/image/index/moreimg.png"
                 class="gltb_moreimg" />更多</a>
        </div>
      </div>
      <div class="display_between marginBottom20">
        <div class="st_block"
             v-for="(item, index) in wonderaudiolist.slice(0, 4)"
             :key="index">
          <img :src="item.image"
               width="280"
               height="158" />
          <p style="padding: 0 20px;"
             class="marginBottom20">
            {{ ellipsis(item.title, 20) }}
          </p>
          <div style="padding: 0 20px;"
               class="display_between">
            <span style="color: #919CA3;"><img src="/image/qiye/huatong.png"
                   class="marginRight10" />{{ item.author }}</span>
            <span class="seeVideo"
                  @click="openVideo(item.title)">观看视频</span>
          </div>
        </div>
      </div>
      <div v-if="activeenvirolist.length != 0">
        <div class="gl_title_bar marginTop40 marginBottom20"
             id="activity">
          <div class="gl_tb_left">
            <span class="normaltitle">活动掠影</span>
            <span class="grayColor marginLeft20">{{ aboutusinfo.title }}</span>
          </div>
          <div class="gl_tb_right">
            <!-- <a href="javascript:;" class="gl_tb_right_more"><img src="/image/index/moreimg.png" class="gltb_moreimg">更多</a> -->
          </div>
        </div>
        <div class="positionBtn EC_width">
          <swiper :options="swiperOption2"
                  ref="mySwiper">
            <swiper-slide v-for="(item, index) in activeenvirolist"
                          :key="index">
              <a href="javascript:;">
                <img :src="item.image"
                     width="382"
                     height="226" />
              </a>
            </swiper-slide>
          </swiper>
          <div class="prevbutton">&lt;</div>
          <div class="nextbutton">&gt;</div>
        </div>
      </div>
      <div v-else
           id="activity"></div>
    </div>
    <videopage :isVideo="isVideo"
               :videoInfo="wonderaudiolist"
               :videoTitle="selTitle"
               @close="isVideo = false"
               :videolist="videolist"
               :video="video"
               @setOther="setOther"></videopage>
  </div>
</template>

<script>
import lodash from 'lodash';
// import videopage from '../../components/video/videomodal';

export default {
  name: 'home',
  //   components: {
  //     videopage
  //   },
  data () {
    return {
      isVideo: false,
      videolist: [],
      video: {
        title: '对2型糖尿病患者进行个体化的强化药物治疗（上）',
        author: '张文瀚 / 北京大学第三医院 / 主任医师',
        time: '90:50',
        src: ''
      },
      videoTitle: '',
      projectimg: '',
      // 关于我们切换index
      aboutCurIndex: '0',
      aboutlist: [],
      apiData: {
        about: {},
        dynamic: {},
        visual: {},
        activity: {}
      },
      swiperOption1: {
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          bulletClass: 'bulletWrite',
          bulletActiveClass: 'bulletWriteActive'
        }
      },
      swiperOption2: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.nextbutton',
          prevEl: '.prevbutton',
          disabledClass: 'my-button-disabled'
        }
      },
      swiperOption3: {
        preventLinksPropagation: true,
        loop: true, // 循环模式选项
        grabCursor: true,
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          bulletClass: 'stWrite',
          bulletActiveClass: 'stbulletWriteActive'
        },
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        on: {
          slideChangeTransitionEnd: () => {
            let swp = this.$refs.mySwiper_info.swiper;
            if (swp != null) {
              let swpindex = swp.activeIndex;
              let listindex = this.prodynatextlist.length;
              let index = 0;
              if (swpindex > listindex) {
                index = 0;
              } else if (swpindex == 0) {
                index = listindex - 1;
              } else {
                index = swp.activeIndex - 1;
              }

              this.projectimg = this.prodynatextlist[index].image;
            } else {
              if (this.prodynatextlist.length > 0) {
                this.projectimg = this.prodynatextlist[0].image;
              }
            }
          }
        }
      },
      /**tianlianpei */
      // 关于我们导航
      aboutusnaviga: [],
      abouts: 0,
      // 关于我们项目介绍
      aboutusinfo: {},
      // 关于我们组织机构
      aboutusorglista: [],
      aboutusorglistb: [],
      aboutusorglistc: [],
      aboutusorglistd: [],
      aboutusorgliste: [],
      // 关于我们学术专家
      baoutusexpertlist: [],
      // 项目动态文字轮播
      prodynatextlist: [],
      // 项目动态图片轮播
      prodynaimglist: [
        {
          img: '../../../static/project/projecta.jpg'
        }
      ],
      // 精彩视听
      wonderaudiolist: [],
      wonderMenuShow: false,
      ShowWonderList: false,
      // 活动掠影
      activEnvioShow: false,
      activeswiperShow: false,
      activeenvirolista: [],
      activeenvirolistb: [],
      activeenvirolistc: [],
      activeenvirolistaone: [],
      activeenvirolistatwo: [],
      activeenvirolistathree: [],
      activeenvirolist: [],

      // 所有数据
      SpecialProject: [],
      // 颜色集合
      colorSet: [],
      // 活动掠影轮播
      activConSwiper: [],
      wonderaudiomorelist: [],
      selTitle: '',
      showvideo: '',
      aboutusorga: '',
      aboutusorgb: '',
      aboutusorgc: '',
      aboutusorgd: '',
      aboutusorge: '',
      aboutusorgtitle: ''
    };
  },
  created () {
    this.GetSpecialProject();
  },
  methods: {
    setOther () { },
    openVideo (title) {
      this.videolist = [];
      this.selTitle = title;
      this.videolist = this.wonderaudiomorelist.filter(item => item.colors == title);
      this.isVideo = true;
    },
    getData () {
      var that = this;
      fetch('/data.json')
        .then(res => {
          return res.json();
        })
        .then(data => {
          that.apiData = data.qyhome.data;
        })
        .catch(erro => {
          console.log(erro);
        });
    },
    aboutTag (index) {
      // 根据index跟新数据
      this.aboutCurIndex = index;
      this.apiData.about = {};
    },
    /**tianlianpei */
    GetSpecialProject () {
      var arry = [];
      this.httphelper
        .post('/api/CrduApi/GetCrduSpecialDataItemDetailsPageLists', {
          specialInfoID: Number(this.$route.query.SpecialID),
          sD_Id: 0
        })
        .then(res => {
          this.SpecialProject = res.data.data;
          if (this.SpecialProject != null) {


            this.SpecialProject.forEach(item => {
              if (item.category == '9' || item.category == '10' || item.category == '11') {
                // 关于我们tab
                this.aboutusnaviga.push({
                  title: item.title,
                  category: item.category
                });
              } else if (item.category == '12') {
                // 项目介绍
                this.aboutusinfo = item;
              } else if (item.category == '7') {
                // 项目动态
                this.prodynatextlist.push(item);
              } else if (item.category == '8') {
                // 精彩视听
                if (item.title != '' && item.title != null) {
                  this.wonderaudiolist.push(item);
                } else {
                  this.wonderaudiomorelist.push(item);
                }
              } else if (item.category == '6') {
                // 颜色设置
                this.colorSet = JSON.parse(item.contents);
              } else if (item.category == '19') {
                // 活动掠影
                this.activeenvirolist.push(item);
                this.activeenvirolista.push(item);
                if (item.author != 'TPJ1') {
                  this.activeenvirolistaone.push(item);
                }
              } else if (item.category == '20') {
                // 活动掠影
                this.activeenvirolist.push(item);
                this.activeenvirolistb.push(item);

                if (item.author != 'TPJ2') {
                  this.activeenvirolistatwo.push(item);
                }
              } else if (item.category == '21') {
                // 活动掠影
                this.activeenvirolist.push(item);
                this.activeenvirolistc.push(item);
                if (item.author != 'TPJ3') {
                  this.activeenvirolistathree.push(item);
                }
              } else if (item.category == '13') {
                // 组织机构-1

                this.aboutusorglista.push(item);
                arry = this.aboutusorglista[0].sDataItemName.split('-');
                if (arry.length > 1) {
                  this.aboutusorga = arry[1];
                  this.aboutusorgtitle = arry[0];
                }
              } else if (item.category == '14') {
                // 组织机构-2
                this.aboutusorglistb.push(item);
                arry = this.aboutusorglistb[0].sDataItemName.split('-');
                if (arry.length > 1) {
                  this.aboutusorgb = arry[1];
                  this.aboutusorgtitle = arry[0];
                }
              } else if (item.category == '15') {
                // 组织机构-3
                this.aboutusorglistc.push(item);
                arry = this.aboutusorglistc[0].sDataItemName.split('-');
                if (arry.length > 1) {
                  this.aboutusorgc = arry[1];
                  this.aboutusorgtitle = arry[0];
                }
              } else if (item.category == '16') {
                // 组织机构-4
                this.aboutusorglistd.push(item);
                arry = this.aboutusorglistd[0].sDataItemName.split('-');
                if (arry.length > 1) {
                  this.aboutusorgd = arry[1];
                  this.aboutusorgtitle = arry[0];
                }
              } else if (item.category == '22') {
                // 组织机构-5
                this.aboutusorgliste.push(item);
                arry = this.aboutusorgliste[0].sDataItemName.split('-');
                if (arry.length > 1) {
                  this.aboutusorge = arry[1];
                  this.aboutusorgtitle = arry[0];
                }
              } else if (item.category == '17') {
                // 评审专家
                this.baoutusexpertlist.push(item);
              }
            });
          }
          this.aboutlist = lodash.chunk(this.baoutusexpertlist, 3);
          this.aboutusnaviga = this.uniquetitle(this.aboutusnaviga);
          this.aboutusnaviga.sort(this.compare('category'));
          this.activeenvirolistc = this.unique(this.activeenvirolistc);
          this.activeenvirolista = this.unique(this.activeenvirolista);
          //    console.log(this.activeenvirolist)
        });
    },
    unique (arr) {
      const res = new Map();
      return arr.filter(arr => !res.has(arr.id) && res.set(arr.id, 1));
    },
    uniquetitle (arr) {
      const res = new Map();
      return arr.filter(arr => !res.has(arr.title) && res.set(arr.title, 1));
    },
    compare (attr) {
      return function (a, b) {
        var val1 = a[attr];
        var val2 = b[attr];
        return val1 - val2;
      };
    },
    navigation (index) {
      if (index == 2) {
        this.$router.push({
          path: '/enterprise/enterpriseList',
          query: {
            SpecialID: Number(this.$route.query.SpecialID)
          }
        });
      } else if (index == 3) {
        this.$router.push({
          path: '/enterprise/enterpriseList2',
          query: {
            SpecialID: Number(this.$route.query.SpecialID)
          }
        });
      }
    },
    //关于我们点击事件
    clkabout (item, index) {
      this.abouts = index;
    },
    proDynamLink (info) {
      console.log(1221212, info)
      return
      let urlstr = info.address;
      let param = urlstr.substr(urlstr.indexOf('?'), urlstr.length - urlstr.indexOf('?'));
      if (urlstr.search('specialRecomme') > -1) {
        const { href } = this.$router.resolve({
          path: '/home/specialRecomme?',
          query: {
            SpecialID: this.$route.query.SpecialID,
            id: info.id
          }
        });
        window.open(href, '_blank');
      } else if (urlstr.search('specialproject/projectdetail') > -1) {
        const { href } = this.$router.resolve({
          path: '/enterprise/enterpriseDetail?' + param
        });
        window.open(href, '_blank');
      } else {
        window.open(info.address, '_blank');
      }
    }
  }
};
</script>

<style scoped>
.blocktou2 {
    width: 387px;
    height: 124px;
    border: 1px solid #e5e5e5;
}

.blocktou2 img {
    margin-left: 18px;
}

/* 轮播 */
.bulletWrite {
    margin-right: 6px;
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #6c9ca3;
    border-radius: 50%;
}

.bulletWriteActive {
    width: 24px;
    height: 10px;
    background: #ffffff;
    border-radius: 4px;
    opacity: 1;
}

.my-bullet-active {
    background: #ff6600;
    opacity: 1;
}

.swiper-pagination {
    text-align: right;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 360px;
    bottom: 22px;
}

.bannerBgWrap {
    height: 400px;
    background: url(/image/qiye/qy_bg.png) center no-repeat;
    background-size: 100%;
}

.btnbanner {
    display: inline-block;
    width: 300px;
    height: 40px;
    background: #d20028;
    border-radius: 20px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    margin-top: 306px;
}

/* 轮播按钮 */
.nextbutton,
.prevbutton,
.nextbutton2,
.prevbutton2 {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    box-sizing: border-box;
    border-radius: 30px;
    background: #0b8586;
    color: #fff;
    position: absolute;
    top: 42%;
    z-index: 10;
    cursor: pointer;
    outline: none;
}

.nextbutton,
.nextbutton2 {
    right: -30px;
    left: auto;
}

.prevbutton,
.prevbutton2 {
    right: auto;
    left: -30px;
}

.my-button-disabled {
    background: #ddd;
    color: #999;
}

/* 面包屑导航 */
.breadcrumb_wrap {
    min-width: 1300px;
    list-style: none;
    height: 40px;
    background: #f2f2f2;
    display: flex;
}

.set_breadcrumb {
    list-style: none;
    line-height: 40px;
    height: 100%;
    padding: 0;
}

.set_breadcrumb li {
    display: inline-block;
    font-size: 12px;
    color: #0b8485;
}

.set_breadcrumb li ~ li:before {
    content: '>';
    margin-left: 4px;
    margin-right: 4px;
    color: #333;
}

.set_breadcrumb li a {
    display: inline-block;
    font-size: 12px;
    color: #333;
}

.breadcrumb_search input {
    width: 200px;
    height: 30px;
    box-sizing: border-box;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    outline: none;
    border: 1px solid #f2f2f2;
    padding-left: 10px;
}

.ArrIcon {
    display: inline-block;
    width: 50px;
    height: 30px;
    background: #0b8686;
    border-radius: 0 30px 30px 0;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

.content {
    flex: 1;
    padding-bottom: 80px;
}

.blockTou {
    width: 192px;
    height: 66px;
    background: #0b8586;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 66px;
}

.blockNormal {
    width: 240px;
    height: 66px;
    border: 1px solid #e5e5e5;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    text-align: center;
    line-height: 66px;
}

.leftText {
    width: 560px;
    margin-right: 46px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.introTitle {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
}

.introText {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 28px;
    text-align: justify;
    height: 180px;
    overflow: hidden;
}

.moreintro {
    width: 162px;
    height: 40px;
    background: #0b8586;
    border-radius: 20px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: 40px;
}

.fiexdrig {
    text-align: right;
    padding-right: 20px;
}

.stWrite {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: #d3d3d3;
    border-radius: 50%;
    margin-right: 12px;
}

.stbulletWriteActive {
    display: inline-block;
    width: 34px;
    height: 13px;
    background: #0b8586;
    border-radius: 6px;
}

.st_block {
    width: 280px;
    padding-bottom: 19px;
    background: #ffffff;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

.seeVideo {
    display: inline-block;
    width: 110px;
    height: 30px;
    border: 1px solid #0b8586;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
    color: #0b8586;
    cursor: pointer;
}

.seeVideo:hover {
    color: #ffffff;
    background: #0b8586;
}

.positionBtn {
    margin-top: 20px;
    position: relative;
    width: 1240px;
}

.swiper-slide {
    text-align: center;
}
</style>
