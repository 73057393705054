<template>
  <div>
    <!-- <breadcrumbPage></breadcrumbPage> -->
    <div class="content EC_width marginTop40">
      <div class="gl_title_bar marginBottom20">
        <div class="gl_tb_left">
          <span class="normaltitle">精彩视听</span>
          <span class="marginLeft10">{{projectname}}</span>
        </div>
        <div class="gl_tb_right">
          <!-- <div class="pagepre">&lt;</div>
                    <div class="pagenext">&gt;</div> -->
        </div>
      </div>
      <div class="display_between marginBottom20"
           v-for="(item,index) in wonderaudiolist"
           :key="index">
        <div class="st_block"
             v-for="(itemn,indexn) in item"
             :key="indexn">
          <img :src="itemn.image"
               width="280"
               height="158">
          <p style="padding: 0 20px;"
             class="marginBottom20">{{itemn.title}}</p>
          <div style="padding: 0 20px;"
               class="display_between">
            <span style="color: #919CA3;"><img src="/image/qiye/huatong.png"
                   class="marginRight10">{{itemn.author}}</span>
            <span class="seeVideo"
                  @click="openVideo(itemn.title)">观看视频</span>
          </div>
        </div>
        <div class="st_block_test"
             v-for="num in 4-item.length"
             :key="num">

        </div>
      </div>
      <!-- <pagination></pagination> -->

    </div>
    <videopage :isVideo="isVideo"
               :videoInfo="wonderaudiolist"
               :videoTitle="selTitle"
               @close="isVideo=false"
               :videolist="videolist"
               :video="video"
               @setOther="setOther"></videopage>
  </div>
</template>

<script>
import lodash from 'lodash'
// import breadcrumbPage from '../home/breadcrumbPage'
// import videopage from '../../components/video/videopage.vue';
export default {
  name: 'enterpriseList2',
  //   components: {
  //     videopage
  //   },
  data () {
    return {
      apiData: {},
      uselist: [],
      wonderaudiolist: [],
      wonderaudiomorelist: [],
      selTitle: '',
      isVideo: false,
      videolist: [],
      video: {},
      projectname: '',
    }
  },
  created () {
    this.getData()
    this.GetSpecialProject()
  },
  watch: {
    $route () {
      document.title = this.projectname
    },
  },
  methods: {
    getData () {
      var that = this
      fetch('/data.json')
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          var usedata = data.enterpriseList2.data
          that.uselist = lodash.chunk(usedata, 4)
          that.apiData = data.enterpriseList2.data
        })
        .catch((erro) => {
          console.log(erro)
        })
    },
    setOther () { },
    openVideo (title) {
      this.videolist = []
      this.selTitle = title
      this.videolist = this.wonderaudiomorelist.filter(
        (item) => item.colors == title
      )
      this.isVideo = true
    },
    GetSpecialProject () {
      this.httphelper
        .post(
          '/api/CrduApi/GetCrduSpecialDataItemDetailsPageLists',
          {
            specialInfoID: Number(this.$route.query.SpecialID),
            sD_Id: 0,
          }
        )
        .then((res) => {
          res.data.data.forEach((item) => {
            if (item.category == '8') {
              // 精彩视听
              // alert(111)
              if (item.title != '' && item.title != null) {
                this.wonderaudiolist.push(item)
              } else {
                this.wonderaudiomorelist.push(item)
              }
            } else if (item.category == '12') {
              this.projectname = item.title
              document.title = this.projectname
            }
          })
          this.wonderaudiolist = lodash.chunk(this.wonderaudiolist, 4)
        })
    },
  },
  components: {
    // breadcrumbPage,
  },
}
</script>

<style scoped>
.content {
    flex: 1;
    padding-bottom: 40px;
}
.pagepre,
.pagenext {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    color: #ccc;
    text-align: center;
    line-height: 30px;
    border-radius: 30px;
    cursor: pointer;
}
.pagepre:hover,
.pagenext:hover {
    background: #0b8485;
    color: #fff;
}
.st_block {
    width: 280px;
    padding-bottom: 19px;
    background: #ffffff;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}
.st_block_test {
    width: 280px;
}
.seeVideo {
    display: inline-block;
    width: 110px;
    height: 30px;
    border: 1px solid #0b8586;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
    color: #0b8586;
    cursor: pointer;
}
.seeVideo:hover {
    color: #ffffff;
    background: #0b8586;
}
.positionBtn {
    margin-top: 20px;
    position: relative;
    width: 1240px;
}
</style>
