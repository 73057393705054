<template>
  <div class="content">
    <div class="main-container container"
         id="main-container">
      <div class="row rows">

        <div class="col-lg-12 btH2">
          <h2>2019年儿童合理用药大会</h2>
        </div>
        <div class="col-lg-12 subnavA">
          <ul>
            <li :class="type==20?'active':''"
                @click="changeType(20)">
              <a href="javascript:0">会议资讯</a>
            </li>
            <li :class="type==21?'active':''"
                @click="changeType(21)"><a href="javascript:0">会议报道</a></li>
            <li :class="type==22?'active':''"
                @click="changeType(22)"><a href="javascript:0">视频回顾</a></li>
          </ul>
        </div>
        <div class="col-lg-12 blog__content mb-30">
          <section class="section">
            <article class="entry post-list"
                     v-for="(item, index) in yzNewList"
                     :key="index">
              <div class="entry__body post-list__body">
                <a :title="item.title"
                   href="javascript:void(0)"
                   @click="topost(item)"><img :src="item.slideShow"
                       :alt="item.title"></a>
                <div class="entry__header">
                 
                  <span class="entry__meta-category"
                        v-for="(qitem, index) in (item.labelName || '').split(',')"
                        :key="index">
                    {{ qitem }}
                  </span>
                </div>
                <div class="entry__excerpt">
                  <h2 class="entry__title">
                    <a :title="item.title"
                       href="javascript:void(0)"
                       @click="topost(item)">{{item.title}}</a>
                  </h2>
                  <p>{{item.abstract}}</p>
                  <ul class="entry__meta"
                      style="text-align:right;">
                    <li class="entry__meta-date">
                      <i class="ui-date"></i>
                      {{ formatTime(item.publishtime, 'YYYY/MM/DD') }}
                    </li>
                    <li class="entry__meta-comments">
                      <i class="fa fa-eye"></i>
                      <a > {{ item.clickVolume }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </article>
            <span class="list-page-data"></span>
            <p class="gengduo"
               data-cate-id="84"
               data-type="zx"
               data-perpage="5"
               data-index="1"
               ref="moreload"
               @click="moreload()">
              <a>+ 加载更多</a>
            </p>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script> 
import format from '@/assets/unit/unit.js';
export default {
  name: 'home',
  data () {
    return {
      cid: 11,
      type: 20,
      yzNewList: []
    };
  },
  created () {
    this.getList()
  },
  methods: {
    changeType (id) {
      this.type = id
      this.getList()
    },
    moreload () {
      let _this = this
      _this.$refs.moreload.dataset.index = parseInt(_this.$refs.moreload.dataset.index) + 1
      _this.httphelper
        .post('/api/CrduApi/GetCrduNewInfoPageList', {
          id: 0,
          parentID: 0,
          column_Type: parseInt(_this.type),
          pageIndex: parseInt(_this.$refs.moreload.dataset.index),
          pageSize: 8
        })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data != null && res.data.data.length > 0) {
              console.log(res.data.data);
              res.data.data.forEach(item => {
                _this.yzNewList.push(item);
              });
            }
          }
        });
    },
    getList () {
      let _this = this
      _this.httphelper
        .post('/api/CrduApi/GetCrduNewInfoPageList', {
          id: 0,
          parentID: 0,
          column_Type: parseInt(_this.type),
          pageIndex: 1,
          pageSize: 8
        })
        .then(res => {
          if (res.data.code == 200) {
            _this.yzNewList = []
            if (res.data.data != null && res.data.data.length > 0) {
              res.data.data.forEach(item => {
                _this.yzNewList.push(item);
              });
            }
          }
        });
    },
    formatTime (datatime, timeformat) {
      return format(datatime, timeformat);
    },
    topost (item) {
      if (item.address != null && item.address != '') {
        let _this = this;
        _this.httphelper
          .post('/api/CrduApi/GetCrduNewInfoById', {
            column_Type: item.cid,
            id: item.id,
            ParentID:0,
            pageIndex: 0,
            pageSize: 0
          })
          .then(res => {
            window.open(item.address, '_blank')
          });
      } else {
        this.$router.push({
          name: 'post',
          query: {
            id: item.id,
            cid: item.cid
          }
        })
      }
    }
  }
};
</script>
 