<template>
  <div>
    <div class="head EC_width display_between">
      <div class="display_center_ud">
        <!-- <img src="/image/qiye/qy_logo.png"> -->
        <span class="fontBold marginLeft10"
              style="font-size: 22px;">{{projectname}}</span>
      </div>
      <div class="display_flex">
        <div v-for="(item,navindex) in headnavigalist "
             :key="navindex"
             class="menu_item"
             :class="{menu_item_active:currentindex==navindex}"
             @click="navigation(navindex,navindex)">
          <a href="javascript:;">{{ item.title}}</a>
        </div>
        <!-- <div class="menu_item" :class="{menu_item_active:currentindex==1}" @click="navigation(2,1)"><a href="javascript:;">关于我们</a></div>
                <div class="menu_item" :class="{menu_item_active:currentindex==2}" @click="navigation(3,2)"><a href="javascript:;">项目动态</a></div>
                <div class="menu_item" :class="{menu_item_active:currentindex==3}" @click="navigation(4,3)"><a href="javascript:;">精选视听</a></div>
                <div class="menu_item" :class="{menu_item_active:currentindex==4}" @click="navigation(5,4)"><a href="javascript:;">活动掠影</a></div> -->
      </div>
    </div>
    <div class="bannerWrap">
      <swiper :options="swiperOption1"
              ref="mySwiper">
        <!-- <swiper-slide>
                    <div class="bannerBgWrap text_center">
                        <div class="btnbanner">
                            <img src="/image/qiye/babtn.png" class="marginRight10 imgvertical-3"><span>社区申请</span>
                        </div>
                    </div>
                </swiper-slide> -->
        <swiper-slide v-for="(item, index) in bannerlist"
                      :key="index">
          <div class="bannerBgWrap text_center">
            <img :src="item.image"
                 class="marginRight10 imgvertical-3">
            <!-- <div class="btnbanner">
                            <img :src="item.image" class="marginRight10 imgvertical-3">
                            <span>社区申请</span>
                        </div> -->
          </div>
        </swiper-slide>
        <div class="swiper-pagination"
             slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'headerEnterprise',
  data () {
    return {
      currentindex: 0,
      swiperOption1: {
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          bulletClass: 'bulletWrite',
          bulletActiveClass: 'bulletWriteActive',
        },
      },
      /** tianlianpei */
      // banner
      bannerlist: [],
      // 颜色集合
      colorSet: [],
      //logo
      AdvertList: [],
      // 头部导航鼠标经过颜色
      headhovercoloe: '',
      //导航
      // headnavigalist: [],
      headnavigalist: [
        { title: '首页' },
        { title: '关于我们' },
        { title: '项目动态' },
        { title: '精彩视听' },
        { title: '活动掠影' },
      ],
      //项目名称
      projectname: '',
    }
  },
  created () {
    this.GetSpecialProject()
    // document.title = this.projectname
  },
  methods: {
    GetSpecialProject () {
      this.httphelper
        .post(
          '/api/CrduApi/GetCrduSpecialDataItemDetailsPageLists',
          {
            specialInfoID: Number(this.$route.query.SpecialID),
            sD_Id: 0,
          }
        )
        .then((res) => {
          this.SpecialProject = res.data.data
          this.SpecialProject.forEach((item) => {
            // 导航
            if (item.category == '5') {
              if (item != null) {
                // this.headnavigalist.push(item)
              }
            } else if (item.category == '1') {
              // banner
              this.bannerlist.push(item)
            } else if (item.category == '6') {
              // 颜色设置
              this.colorSet = JSON.parse(item.contents)
              this.headhovercoloe = this.colorSet[0].navselectcolor
            } else if (item.category == '4') {
              // 广告位
              this.AdvertList.push(item)
            } else if (item.category == '12') {
              this.projectname = item.title
              document.title = this.projectname
            }
          })

        })
    },

    navigation (index) {
      this.currentindex = index
      var targetOffset = 0
      if (index == 0) {
        this.$router.push({
          path: '/enterprise/qyhome',
          query: {
            SpecialID: Number(this.$route.query.SpecialID),
          },
        })
        $('html,body').animate(
          {
            scrollTop: targetOffset,
          },
          1000
        )
      } else if (index == 4) {
        this.$router.push({
          path: '/enterprise/qyhome',
          query: {
            SpecialID: Number(this.$route.query.SpecialID),
          },
        })
        setTimeout(() => {
          targetOffset = $(document).height() - $(window).height()
          $('html,body').animate(
            {
              scrollTop: targetOffset,
            },
            1000
          )
        }, 50)
      } else if (index == 1) {
        this.$router.push({
          path: '/enterprise/qyhome',
          query: {
            SpecialID: Number(this.$route.query.SpecialID),
          },
        })
        targetOffset = 400 + 'px'
        $('html,body').animate(
          {
            scrollTop: targetOffset,
          },
          1000
        )
      } else if (index == 2) {
        this.$router.push({
          path: '/enterprise/enterpriseList?',
          query: {
            SpecialID: Number(this.$route.query.SpecialID),
          },
        })
      } else if (index == 3) {
        this.$router.push({
          path: '/enterprise/enterpriseList2?',
          query: {
            SpecialID: Number(this.$route.query.SpecialID),
          },
        })
      }
    },
  },
  watch: {
    $route () {
      document.title = this.projectname
    },
  },
}
</script>

<style scoped>
/* 头部 */
.head {
    height: 77px;
    /* position: fixed;
    z-index: 99; */
}
.menu_item {
    padding: 0 20px;
    line-height: 77px;
    box-sizing: border-box;
}

.menu_item_style {
    padding: 0 20px;
    line-height: 77px;
    box-sizing: border-box;
}

.menu_item a {
    color: #333;
    font-weight: bold;
}

.menu_item_active {
    border-bottom: 3px solid #0b8485;
}

.menu_item_active a {
    color: #0b8485;
}
/* 轮播 */
.bulletWrite {
    margin-right: 6px;
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #6c9ca3;
    border-radius: 50%;
}

.bulletWriteActive {
    width: 24px;
    height: 10px;
    background: #ffffff;
    border-radius: 4px;
    opacity: 1;
}

.my-bullet-active {
    background: #ff6600;
    opacity: 1;
}

.swiper-pagination {
    text-align: right;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 360px;
    bottom: 22px;
}
.bannerWrap {
    width: 100%;
}
.bannerBgWrap img {
    width: 100%;
    /* height: 400px; */
    /* background: url(/image/qiye/qy_bg.png) center no-repeat; */
    /* background-size: 100% 100%; */
}
.btnbanner {
    display: inline-block;
    width: 300px;
    height: 40px;
    background: #d20028;
    border-radius: 20px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    margin-top: 306px;
}
/* 轮播按钮 */
.nextbutton,
.prevbutton,
.nextbutton2,
.prevbutton2 {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    box-sizing: border-box;
    border-radius: 30px;
    background: #0b8586;
    color: #fff;
    position: absolute;
    top: 42%;
    z-index: 10;
    cursor: pointer;
    outline: none;
}

.nextbutton,
.nextbutton2 {
    right: -30px;
    left: auto;
}

.prevbutton,
.prevbutton2 {
    right: auto;
    left: -30px;
}

.my-button-disabled {
    background: #ddd;
    color: #999;
}
</style>
