<template>
    <div class="wrap" v-if="isVideo">
        <div class="contentWrap">
            <span class="closeModal" @click="closeModal">关闭</span>
            <div class="display_between" style="">
                <div class="preVideo" @click="setOther">
                    <img src="/image/qiye/shangyige.png" class="marginRight10 imgvertical-3">{{videoTitle}}
                </div>
                <div class="preVideo" @click="taglist=!taglist">
                    <img src="/image/qiye/menu.png" class="marginRight10 imgvertical-1">目录
                </div>
            </div>
            <div class="videoWrap">
                <div class="video">
                    <!-- <video :src="videolist[0].image">
                        当前浏览器不支持 video直接播放，点击这里下载视频： <a href="myvideo.webm">下载视频</a>
                    </video> -->
                    <video @contextmenu.prevent="" controls="controls" :src="videolist[0].image" @timeupdate="updateTime" @canplay="getDuration" ref="video" controlslist="nodownload" style="width:100%;height:100%;"></video>
                    <img src="/image/qiye/guanbar.png" v-show="taglist" class="switch" @click="tagListClick(false)">
                    <img src="/image/qiye/kaibar.png" v-show="!taglist" class="switch" @click="tagListClick(true)">
                </div>
                <div class="videoList" v-if="taglist">
                    <div class="padWrap">

                        <p class="curTitle">{{videoname}}</p>
                        <!-- <p class="intro">讲师：{{video.author}}</p> -->
                        <p class="intro">时长：{{videoTime}} <img src="/image/qiye/barver.png" class="marginLeft20"></p>
                        <div class="moreVideoWrap">
                            <div class="mVtitle">
                                <span>更多视频</span>
                            </div>
                            <div class="videoLinkWrap" v-for="(item,index) in videolist" :key="index" @click="upvideosrc(item)">
                                <p class="listVideoTitle">{{ellipsis(item.res_Title,14)}}</p>
                                <!-- <p class="listVideointro">{{item.time}}</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'videopage',
    props: {
        isVideo: {
            type: Boolean,
            default: false,
        },
        videolist: {
            type: Array,
        },
        video: {
            type: Object,
        },
        videoTitle: String,
        videoInfo: {
            type: Array,
        },
    },
    data() {
        return {
            taglist: true,
            videosrc: '',
            videoTime: '',
            videoname: '',
            jishu: 0,
        }
    },
    created() {
        // alert(document.getElementById('videoPlayer').duration)
    },
    methods: {
        getDuration() {
            if (this.jishu == 0) {
                this.videoname = this.videolist[0].res_Title
            }
            let time = this.$refs.video.duration //获取视频的总时长
            const hour =
                parseInt(time / 3600) < 10
                    ? '0' + parseInt(time / 3600)
                    : parseInt(time / 3600)
            const min =
                parseInt((time % 3600) / 60) < 10
                    ? '0' + parseInt((time % 3600) / 60)
                    : parseInt((time % 3600) / 60)
            const sec =
                parseInt((time % 3600) % 60) < 10
                    ? '0' + parseInt((time % 3600) % 60)
                    : parseInt((time % 3600) % 60)
            if (hour > 0) {
                this.videoTime = hour + ':' + min + ':' + sec
            } else {
                this.videoTime = min + ':' + sec
            }
        },
        updateTime() {
            // let s = this.$refs.video.currentTime //获取当前播放时间
            // console.log(s + '=======获取当前播放时间')
        },
        upvideosrc(item) {
            this.jishu = 1
            // if (item.res_Title == this.videoname) {
            //     return
            // }
            this.$refs.video.src = item.image
            this.videoname = item.res_Title
            // console.log(item)
        },
        setOther() {
            this.$emit('setOther')
        },
        tagListClick(item) {
            this.taglist = item
        },
        closeModal() {
            this.$emit('close')
            this.jishu = 0
        },
    },
}
</script>

<style scoped>
.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.closeModal {
    position: absolute;
    right: -30px;
    top: -30px;
    color: #988687;
    font-weight: bold;
    cursor: pointer;
}

.contentWrap {
    position: relative;
    height: 80%;
    background: #15161b;
    box-sizing: border-box;
    padding: 30px 100px;
    padding-right: 50px;
    width: 80%;
}

.preVideo {
    color: #dddddd;
    font-size: 16px;
    cursor: pointer;
}

.video {
    flex: 1;
    background: #0b485d;
    position: relative;
}

.videoWrap {
    display: flex;
    height: 80%;
    margin-top: 54px;
}

.video video {
    width: 100%;
    height: 100%;
}

.videoList {
    width: 300px;
    max-height: 620px;
    box-sizing: border-box;

    overflow: auto;
    position: relative;
}

.padWrap {
    padding: 15px 20px 0 48px;
}

.curTitle {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #dddddd;
    line-height: 32px;
    margin-bottom: 20px;
}

.intro {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.3);
    line-height: 30px;
}

.mVtitle span {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #dddddd;
    line-height: 32px;
    border-left: 1px solid #ddd;
    padding-left: 13px;
}

.mVtitle {
    margin-top: 55px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 18px;
    margin-bottom: 0px;
}

.videoLinkWrap {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 21px;
    padding-top: 20px;
    cursor: pointer;
}

.listVideoTitle {
    color: #dddddd;
}

.listVideointro {
    color: #6f7071;
    margin-top: 8px;
}

.videoLinkWrap:hover .listVideoTitle {
    color: #00e5e6;
}

.switch {
    position: absolute;
    right: -17px;
    top: 42%;
    z-index: 100;
    cursor: pointer;
}

.videoList::-webkit-scrollbar {
    width: 5px;
    background: transparent;
}

/* 滚动槽 */
.videoList::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* 滚动条滑块 */
.videoList::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #494a4f;
}

.videoList::-webkit-scrollbar-thumb:window-inactive {
    background: #0b8586;
}

.guan {
    /*display: ;*/
}

.kai {
    display: none;
}
</style>
