import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router/router';
import httphelper from './assets/unit/HttpHelper';

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Ellipsis from './assets/unit/ellipsis';
import routerlist from './router/menu';
import iconfont from './assets/css/iconfont.css';
import formatter from './assets/unit/unit';
import crducss from './assets/css/home.css';
import crdubootstrapcss from './assets/css/bootstrap.min.css';
import crdushuaiquancss from './assets/css/shuaiquan.css';
import postlist from './assets/css/post-list.css';
import videopage from './components/video/videomodal';

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.prototype.formatter = formatter;
Vue.prototype.iconfont = iconfont;
Vue.prototype.isDebug = false;
Vue.prototype.ellipsis = Ellipsis;

Vue.prototype.httphelper = httphelper;
Vue.prototype.routerlist = routerlist;
Vue.use(VueRouter);
Vue.use(VueAwesomeSwiper);

Vue.use(ElementUI);
Vue.use(Vant);
Vue.config.productionTip = false;

Vue.use(crducss);
Vue.use(videopage);
Vue.use(crdubootstrapcss);
Vue.use(crdushuaiquancss);
Vue.use(postlist);
// VueCookies.config('15d', null, window.location.host.search('localhost') > -1 ? '' : window.location.host.search('chinagp.net') > -1 ? '.chinagp.net' : '.gp519.com');
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
