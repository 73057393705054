<template>
  <div class="content">
    <main class="main oh" id="main">

      <!-- Featured Posts Grid -->
      <section class="featured-posts-grid">
        <div class="container clearfix">
          <!-- 幻灯片轮播 -->
          <div class="featured-posts-grid__item featured-posts-grid__item--lg">
            <div id="owl-hero-grid" class="owl-carousel owl-theme owl-carousel--dots-inside owl-loaded owl-drag">
              <div class="owl-stage-outer">
                <div class="owl-stage">
                  <div class="owl-item active" style="width: 100%;">
                    <el-carousel :interval="5000" arrow="always" class="entry featured-posts-grid__entry" indicator-position="none">
                      <el-carousel-item v-for="(item, index2) in topRotation" :key="index2" style="height:364px">
                        <div class="thumb-bg-holder owl-lazy" style="  opacity: 1;">
                          <a :title="item.title" target="_blank" :href="item.url">
                            <img :src="item.resUrl" :alt="item.title"></a>
                        </div>
                        <div class="thumb-text-holder thumb-text-holder-sq">
                          <h2 class="thumb-entry-title">
                            <a :title="item.title" target="_blank" :href="item.url">{{item.title}}</a>
                          </h2>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 右上右下广告位 -->
          <div class="featured-posts-grid__item featured-posts-grid__item--sm" v-if="upRightAdvert!=null && upRightAdvert.length>0">
            <article class="entry featured-posts-grid__entry" v-for="(item, index) in upRightAdvert.slice(0,1)" :key="index">
              <div class="thumb-bg-holder lunbo2">
                <a :title="item.title" target="_blank" :href="item.url"><img :src="item.resUrl" :alt="item.title"></a>
              </div>
              <div class="thumb-text-holder thumb-text-holder-sq">
                <h2 class="thumb-entry-title thumb-entry-title--sm">
                  <a :title="item.title" target="_blank" :href="item.url"> {{item.title}}</a>
                </h2>
              </div>
            </article>
          </div>
          <div class="featured-posts-grid__item featured-posts-grid__item--sm" v-if="lowerRightAdvert!=null && lowerRightAdvert.length>0">
            <article class="entry featured-posts-grid__entry" v-for="(item, index) in lowerRightAdvert.slice(0,1)" :key="index">
              <div class="thumb-bg-holder lunbo2">
                <a :title="item.title" target="_blank" :href="item.url"><img :src="item.resUrl" :alt="item.title"></a>
                <!--<div class="bottom-gradient"></div>-->
              </div>
              <div class="thumb-text-holder thumb-text-holder-sq">
                <h2 class="thumb-entry-title thumb-entry-title--sm">
                  <a :title="item.title" target="_blank" :href="item.url"> {{item.title}}</a>
                </h2>
              </div>
            </article>
          </div>
        </div>
      </section>
      <div class="main-container container mt-20" id="main-container">
        <!-- Content -->
        <div class="row rows">
          <!-- Posts -->
          <div class="col-lg-8 blog__content mb-10" style="padding:18px 25px 0 15px;">
            <!-- Hot News -->
            <section class="section tab-post mb-10">
              <div class="title-wrap">
                <h3 class="section-title fa fa-newspaper-o fa-2x tabs__item_0_click" style="color:#FC6D41;cursor: pointer;" @click="yzclick()"> 医周</h3>
                <div class="tabs tab-post__tabs">
                  <ul class="tabs__list">
                    <li class="tabs__item " v-for="(item, index) in yzcolumnList" :key="index" @click="tagDatahot(item.id,1)">
                      <a class="tabs__trigger"> {{ item.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- tab content -->
              <div class="tabs__content tabs__content-trigger tab-post__tabs-content">
                <!-- 指南列表 -->
                <div class="tabs__content-pane  tabs__content-pane--active" id="tab-news0">
                  <section class="section">
                    <article class="entry post-list" v-for="(item, index) in yzNewList" :key="index">
                      <div class="entry__body post-list__body">
                        <a :title="item.title" href="javascript:void(0)" @click="topost(item)"><img :src="item.slideShow" :alt="item.title"></a>
                        <div class="entry__header">

                          <span class="entry__meta-category" style="cursor: pointer;" v-for="(qitem, index) in (item.labelName || '').split(',')" @click="tobqList(qitem)" :key="index">
                            {{ qitem }}
                          </span>
                        </div>
                        <div class="entry__excerpt">
                          <h2 class="entry__title">
                            <a :title="item.title" href="javascript:void(0)" @click="topost(item)">{{item.title}}</a>
                          </h2>
                          <p>{{item.abstract}}</p>
                          <ul class="entry__meta" style="text-align:right;">
                            <li class="entry__meta-date">
                              <i class="ui-date"></i>
                              {{ formatTime(item.publishtime, 'YYYY/MM/DD') }}
                            </li>
                            <li class="entry__meta-comments">
                              <i class="fa fa-eye"></i>
                              <a> {{ item.clickVolume }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </article>
                    <span class="list-page-data"></span>
                    <p class="gengduo" data-cate-id="84" data-type="zx" data-perpage="5" data-index="1" ref="moreload" @click="moreload()">
                      <a>+ 加载更多</a>
                    </p>
                  </section>
                </div>
              </div>
            </section>
          </div> <!-- end posts -->
          <!-- Sidebar -->
          <aside class="col-lg-4 sidebar sidebar--right col-lg-4s">
            <!-- 专家 start -->
            <div class="widget">
              <h4 class="widget-title ">
                <i class="fa fa-graduation-cap"></i> 专家
                <a href="content?cid=6" class="all-posts-url" target="_blank">更多</a>
              </h4>
              <div class="owl-single owl-carousel owl-theme owl-loaded owl-drag">
                <div class="owl-stage-outer">
                  <div class="owl-stage">
                    <div class="owl-item" style="width: 389px;">
                      <el-carousel :interval="5000" arrow="always" class="entry" indicator-position="none">
                        <el-carousel-item v-for="(item, index2) in expertlist" :key="index2">
                          <div class="entry__img-holder mb-0">
                            <a href="javascript:void(0)" @click="topost(item)">
                              <div class="thumb-bg-holder">
                                <img :src="item.slideShow" class="entry__img owl-lazy" alt="" style="opacity: 1;">
                                <div class="bottom-gradient"></div>
                              </div>
                            </a>
                          </div>
                          <div class="thumb-text-holder">
                            <h2 class="thumb-entry-title thumb-entry-title--sm">
                              <a :title="item.title" href="javascript:void(0)" @click="topost(item)">{{item.title}}</a>
                            </h2>
                          </div>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 专家 end -->
            <!-- 会议视频 -->
            <div class="widget">
              <h4 class="widget-title ">
                <i class="fa fa-video-camera"></i> 会议视频
                <a href="content?cid=7" class="all-posts-url" target="_blank">更多</a>
              </h4>
              <div class="owl-single owl-carousel owl-theme owl-loaded owl-drag">
                <div class="owl-stage-outer">
                  <div class="owl-stage">
                    <div class="owl-item" style="width: 389px;">
                      <!-- <el-carousel :interval="5000" arrow="always" class="entry" indicator-position="none">
                        <el-carousel-item v-for="(item, index3) in meetvideolist" :key="index3"> -->
                      <el-carousel indicator-position="none" :interval="5000" arrow="always" class="entry">
                        <el-carousel-item v-for="(item, index3) in meetvideolist" :key="index3">
                          <div class="entry__img-holder mb-0">
                            <a href="javascript:void(0)" @click="topost(item)">
                              <div class="thumb-bg-holder">
                                <img :src="item.slideShow" class="entry__img owl-lazy" alt="" style="opacity: 1;">
                                <div class="bottom-gradient"></div>
                              </div>
                            </a>
                          </div>
                          <div class="thumb-text-holder">
                            <h2 class="thumb-entry-title thumb-entry-title--sm">
                              <a :title="item.title" href="javascript:void(0)" @click="topost(item)">{{item.title}}</a>
                            </h2>
                          </div>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 会议视频end -->
            <!-- 培训视频 -->
            <div class="widget">
              <h4 class="widget-title">
                <i class="fa fa-users"></i> 培训视频
                <a href="content?cid=8" class="all-posts-url" target="_blank">更多</a>
              </h4>
              <div class="owl-single owl-carousel owl-theme owl-loaded owl-drag">
                <div class="owl-stage-outer">
                  <div class="owl-stage">
                    <div class="owl-item" style="width: 389px;">
                      <el-carousel indicator-position="none" :interval="5000" arrow="always" class="entry">
                        <el-carousel-item v-for="(item, index4) in trainvideolist" :key="index4">
                          <div class="entry__img-holder mb-0">
                            <a href="javascript:void(0)" @click="topost(item)">
                              <div class="thumb-bg-holder">
                                <img :src="item.slideShow" class="entry__img owl-lazy" alt="" style="opacity: 1;">
                                <div class="bottom-gradient"></div>
                              </div>
                            </a>
                          </div>
                          <div class="thumb-text-holder">
                            <h2 class="thumb-entry-title thumb-entry-title--sm">
                              <a :title="item.title" href="javascript:void(0)" @click="topost(item)">{{item.title}}</a>
                            </h2>
                          </div>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- 培训视频end -->
            <!-- 专题 start -->
           <!-- 专题无图片,所以换成 热门标签 -->
            <div class="widget widget_tag_cloud">
                <h4 class="widget-title "><i class="fa fa-tag"></i> 热门标签</h4>
                <div class="tagcloud">
                  <a v-for="(item, index) in popularList" :key="index" href="javascript:void(0)" @click="tobqList(item.labelName)"> {{item.labelName}}</a>
                </div>
              </div>
           
            <!-- <div class="widget widget-social-subscribers">
              <h4 class="widget-title "><i class="fa fa-th"></i>专题<a href="content?cid=9" class="all-posts-url">更多</a></h4>
              <ul class="widget-social-subscribers__list">
                <li class="widget-social-subscribers__item" v-for="(item, index1) in speciallist" :key="index1">
                  <a href="javascript:void(0)" @click="topost(item)" class="widget-social-subscribers__url widget-social-subscribers" :style="rbg(index1)">
                    <img :src="item.slideShow">
                    <span class="widget-social-subscribers__number">{{item.title}}</span>
                    <span class="widget-social-subscribers__text"></span>
                  </a>
                </li>
              </ul>
            </div> -->
            <!-- 专题 end -->
          </aside> <!-- end sidebar -->
        </div> <!-- end content -->
      </div> <!-- end main container -->
      <div>
        <div class="main-container container mt-0 bingan" id="main-container">
          <div class="row rows">
            <!-- 左侧 start -->
            <div class="col-lg-8 blog__content mb-10" v-show="!isMobile()" style="padding:18px 25px 0 15px;">
              <section class="section tab-post mb-10">
                <div class="title-wrap">
                  <h3 class="section-title fa fa-stethoscope fa-2x " style="color:#FC6D41;cursor: pointer;" @click="basclick()"> 病案室</h3>
                  <div class="tabs tab-post__tabs">
                    <ul class="tabs__list">
                      <li class="tabs__item" :class="{ 'tabs__item_active': index1 == 0 }" v-for="(item, index1) in bascolumnList" v-bind:key="index1" @click="tagbasData(item.id,0)">
                        {{ item.name }}
                      </li>
                    </ul> <!-- end tabs -->
                  </div>
                </div>
                <div class="tabs__content tabs__content-trigger">
                  <div class="tabs__content-pane  tabs__content-pane--active" id="tab-cases0">
                    <div class="row">
                      <div class="col-md-6 mb-40" v-for="(item, index) in basNewList" :key="index">
                        <article class="entry">
                          <div class="entry__img-holder">
                            <div class="thumb-container thumb-50">
                              <a :title="item.title" href="javascript:void(0)" @click="topost(item)"><img :src="item.slideShow" :alt="item.title"></a>
                              <a href="javascript:void(0)" @click="topost(item)">
                                <img :src="item.slideShow">
                              </a>
                            </div>
                          </div>
                          <div class="entry__body">
                            <div class="entry__header">
                              <h2 class="entry__title">
                                <a href="javascript:void(0)" @click="topost(item)">
                                  {{item.title}} </a>
                              </h2>
                              <ul class="entry__meta">
                                <li class="entry__meta-author">
                                  <i class="ui-author"></i>
                                  <a>{{item.source}}</a>
                                </li>
                                <li class="entry__meta-date">
                                  <i class="ui-date"></i>
                                  {{ formatTime(item.publishtime, 'YYYY/MM/DD') }}
                                </li>
                                <li class="entry__meta-comments">
                                  <i class="fa fa-eye"></i>
                                  <a> {{  item.clickVolume  }}</a>
                                </li>
                              </ul>
                            </div>
                            <div class="entry__excerpt">
                              <p> {{ ellipsis(item.abstract, 25) }}</p>
                            </div>
                          </div>
                        </article>
                      </div>
                      <span class="list-page-data" data-p="1" data-count="2"></span>
                      <p class="gengduo" data-cate-id="17" data-type="zx" data-perpage="5" data-index="1" ref="basmoreload" @click="basmoreload()">
                        <a>+ 加载更多</a>
                      </p>
                    </div>
                  </div>
                  <div class="tabs__content-pane " id="tab-cases1">
                    <div class="row">
                      <span class="list-page-data" data-p="1" data-count="0"></span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <!-- 左侧 end -->
            <!-- 右侧 start -->
            <aside class="col-lg-4 sidebar sidebar--right col-lg-4s">
              <!-- 热门标签 start -->
              <!-- <div class="widget widget_tag_cloud">
                <h4 class="widget-title "><i class="fa fa-tag"></i> 热门标签</h4>
                <div class="tagcloud">
                  <a v-for="(item, index) in popularList" :key="index" href="javascript:void(0)" @click="tobqList(item.labelName)"> {{item.labelName}}</a>
                </div>
              </div> -->
              <!-- 热门标签 end -->
            </aside>
            <!-- 右侧 end -->
          </div>
        </div>
      </div>
      <div id="back-to-top">
        <a aria-label="Go to top"><i class="ui-arrow-up"></i></a>
      </div>
    </main>
  </div>
</template>
<script>
import format from '@/assets/unit/unit.js';
export default {
  name: 'home',
  data() {
    return {
      cid: 11,
      c_basid: 17,
      // topAdvert: null, //顶部广告
      topRotation: null, //轮播
      upRightAdvert: null, //首页右上
      lowerRightAdvert: null, //首页右下
      linksAdvert: null, //友情链接,
      lowerRotation: null, //底部二维码
      popularList: null, //热门内容,
      expertlist: [],
      meetvideolist: [],
      trainvideolist: [],
      speciallist: [],
      yzcolumnList: [],
      bascolumnList: [],
      yzNewList: [],
      basNewList: [],
      parentID: 0,
      ba_parentID: 0
    };
  },
  created() {
    //获取广告位数据
    this.GetRotationInfo();
    //热门标签
    this.GetPopularContent();
    //首页资讯
    this.GetCrduNewInfoList();
    //栏目
    this.GetColumsInfoAllList();
    this.parentID = 10
    this.tagDatahot(0, 1)

    this.ba_parentID = 16
    this.tagbasData(0, 1)
  },
  methods: {
    //专题的背景色
    rbg(index) {
      switch (index) {
        case 0:
          return { background: '#39599f' };
        case 1:
          return { background: '#55ACEE' };
        case 2:
          return { background: '#DE4B39' };
        case 3:
          return { background: '#ef822a' };
        case 4:
          return { background: '#c61d23' };
        case 5:
          return { background: '#e1306c' };
        default:
          return { background: '#39599f' };
      }
    },
    /**获取首页中部新闻信息 */
    GetGpSiteNewInfoPageList() {
      let _this = this;
      let arrcolumntop = _this.columntopList;
      for (let i = 0; i < arrcolumntop.length; i++) {
        _this.httphelper
          .post('/api/NewApi/GetGpSiteNewInfoPageList', {
            id: 0,
            parentID: 0,
            column_Type: arrcolumntop[i].id,
            pageIndex: 0,
            pageSize: 10
          })
          .then(res => {
            if (res.data.code == 200) {
              if (res.data.data.gpSiteNewInfoViewsViews != null && res.data.data.gpSiteNewInfoViewsViews.length > 0) {
                res.data.data.gpSiteNewInfoViewsViews.forEach(item => {
                  _this.newlist.push(item);
                });
              }
              _this.newlistdata = _this.newlist.filter(item => item.cid == 42);
              _this.consultListdata = _this.newlist.filter(item => item.cid == 44);
            }
          });
      }
    },
    /**获取栏目数据 */
    GetColumsInfoAllList() {
      let _this = this;
      _this.httphelper
        .post('/api/CrduApi/GetColumsInfoAllList', {
          cType: 1,
          isCHorEN: 1
        })
        .then(res => {
          if (res.data.code == 200) {
            let data = res.data.data.sort(function (a, b) {
              return a.sort - b.sort;
            });

            data.forEach(item => {
              if (item.parentid == '10') {
                //医周
                if (item.id == 11 || item.id == 12 || item.id == 13 || item.id == 14 || item.id == 15) {
                  _this.yzcolumnList.push(item);
                }
              }
              if (item.parentid == '16') {
                //病案室
                if (item.id == 17 || item.id == 18) {
                  _this.bascolumnList.push(item);
                }
              }
            });
          }
        });
    },
    /** 获取轮播数据*/
    GetRotationInfo() {
      let _this = this;
      let arr = [1, 2, 3, 4, 5, 6]; //分别代表首页顶部广告，轮播，首页右上，首页右下，友情链接，底部二维码
      for (let i = 0; i < arr.length; i++) {
        _this.httphelper
          .post('/api/CrduApi/GetRotationInfo', {
            type: arr[i],
            ruselocation: 1,
            source: 1
          })
          .then(res => {
            if (res.data.code == 200) {
              // if (arr[i] == 1) {
              //   _this.topAdvert = res.data.data || null;
              // }
              if (arr[i] == 2) {
                _this.topRotation = res.data.data || null;
              }
              if (arr[i] == 3) {
                _this.upRightAdvert = res.data.data || null;
              }
              if (arr[i] == 4) {
                _this.lowerRightAdvert = res.data.data || null;
              }
              if (arr[i] == 5) {
                _this.linksAdvert = res.data.data || null;
              }
              if (arr[i] == 6) {
                _this.lowerAdvert = res.data.data || null;
              }
            }
          });
      }
    },
    /** 轮播、广告图跳转外链
        轮播、广告图跳转外链 */
    Towindowurl(url, tougao) {
      let urlstr = url;
      let param = urlstr.substr(urlstr.indexOf('?'), urlstr.length - urlstr.indexOf('?'));
      if (urlstr.search('grasshealList/grasshealListdetail') > -1) {
        const { href } = this.$router.resolve({
          path: '/home/inforDetails?' + param
        });
        window.open(href, '_blank');
      } else if (url != null && url != '') {
        if (tougao == true) {
          this.dialogTableVisible = true;
        } else {
          window.open(url);
        }
      }
    },
    /**获取首页热门内容*/
    GetPopularContent() {
      let _this = this;
      _this.httphelper.get('/api/CrduApi/GetPopularContent').then(res => {

        if (res.code == 200) {
          _this.popularList = res.data;
        }
      });
    },
    //首页栏目资讯
    GetCrduNewInfoList() {
      let _this = this;
      let arr = [6, 7, 8, 9];
      for (let i = 0; i < arr.length; i++) {
        _this.httphelper
          .post('/api/CrduApi/GetCrduNewInfoPageList', {
            id: 0,
            parentID: 0,
            column_Type: arr[i],
            pageIndex: 0,
            pageSize: arr[i] == 9 ? 6 : arr[i] == 11 ? 8 : arr[i] == 17 ? 2 : 100
          })
          .then(res => {
            if (res.data.code == 200) {
              if (res.data.data != null && res.data.data.length > 0) {
                res.data.data.forEach(item => {
                  if (item.cid == 6) {//专家

                    _this.expertlist.push(item); 
                  }
                  if (item.cid == 7) {//会议视频

                    _this.meetvideolist.push(item);
                  }
                  if (item.cid == 8) {//培训视频
                    _this.trainvideolist.push(item);
                  }
                  if (item.cid == 9) {//专题

                    _this.speciallist.push(item);
                  }
                  // if (item.cid == 11) {//政讯

                  //   _this.yzNewList.push(item);
                  // }
                  // if (item.cid == 17) {//病例
                  //   _this.basNewList.push(item);
                  // }
                });
              }
            }
          });
      }
    },
    yzclick() {
      this.$refs.moreload.dataset.index = 1
      this.parentID = 10
      this.tagDatahot(0, 1)
    },
    //医周栏目事件
    tagDatahot(id, pageIndex) {
      let _this = this
      _this.yzNewList = [];
      _this.cid = id
      if (id != 0) {
        _this.parentID = 0
        _this.$refs.moreload.dataset.index = 1
      }
      _this.httphelper
        .post('/api/CrduApi/GetCrduNewInfoPageList', {
          id: 0,
          parentID: _this.parentID,
          column_Type: _this.cid,
          pageIndex: pageIndex,
          pageSize: 10
        })
        .then(res => {
           
          if (res.data.code== 200) {
            if (res.data.data!= null && res.data.data.length > 0) {
              _this.yzNewList = res.data.data;
            }
          }
        });
    },
    basclick(){
       this.$refs.basmoreload.dataset.index = 1
      this.ba_parentID = 16
      this.tagbasData(0, 1)
    },
    //病案室栏目事件
    tagbasData(id, pageIndex) {
      let _this = this
      // _this.$refs.basmoreload.dataset.index = 1
      _this.basNewList = [];
      _this.c_basid = id
       if (id != 0) {
        _this.ba_parentID = 0
        _this.$refs.basmoreload.dataset.index = 1
      }
      _this.httphelper
        .post('/api/CrduApi/GetCrduNewInfoPageList', {
          id: 0,
          parentID: _this.ba_parentID,
          column_Type: _this.c_basid,
          pageIndex: pageIndex,
          pageSize: 2
        })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data != null && res.data.data.length > 0) {
              _this.basNewList = res.data.data;
            }
          }
        });
    },
    //病案室加载更多
    basmoreload() {
      let _this = this
      _this.$refs.basmoreload.dataset.index = parseInt(_this.$refs.basmoreload.dataset.index) + 1
      _this.httphelper
        .post('/api/CrduApi/GetCrduNewInfoPageList', {
          id: 0,
          parentID: _this.ba_parentID,
          column_Type: _this.c_basid,
          pageIndex: parseInt(_this.$refs.basmoreload.dataset.index),
          pageSize: 2
        })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data != null && res.data.data.length > 0) {
              res.data.data.forEach(item => {
                _this.basNewList.push(item);
              });
            }
          }
        });
    },
    //医周加载更多
    moreload() {
      let _this = this
      _this.$refs.moreload.dataset.index = parseInt(_this.$refs.moreload.dataset.index) + 1
      _this.httphelper
        .post('/api/CrduApi/GetCrduNewInfoPageList', {
          id: 0,
          parentID: _this.parentID,
          column_Type: _this.cid,
          pageIndex: parseInt(_this.$refs.moreload.dataset.index),
          pageSize: 10
        })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data!= null && res.data.data.length > 0) {
              res.data.data.forEach(item => {
                _this.yzNewList.push(item);
              });
            }
          }
        });
    },
    formatTime(datatime, timeformat) {
      return format(datatime, timeformat);
    },
    topost(item) {
      if (item.address != null && item.address != '') {
        let _this = this;
        _this.httphelper
          .post('/api/CrduApi/GetCrduNewInfoById', {
            column_Type: item.cid,
            id: item.id,
            ParentID:0,
            pageIndex: 0,
            pageSize: 0
          })
          .then(res => {
            window.open(item.address, '_blank')
          });
      } else {
        this.$router.push({
          name: 'post',
          query: {
            id: item.id,
            cid: item.cid
          }
        })
      }
    },
    tobqList(str) {
      this.$router.push({
        name: 'list',
        query: {
          typeName: str,
          typeClass: 2
        }
      })
    },
    toList(id) {
      console.log(id);
    },
    isMobile() {
      let m = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      return navigator.userAgent.match(m);
    }
  }
};
</script>

<style>
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
</style>