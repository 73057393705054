<template>
  <div class="wrap">
    <headerEnterprise></headerEnterprise>
    <router-view></router-view>
    <footer_index></footer_index>
  </div>
</template>

<script>
import footer_index from "../home/footer_index"
import headerEnterprise from "./headerEnterprise"
export default {
  name: "index",
  components: {
    footer_index,
    headerEnterprise
  }
}
</script>

<style scoped>
.wrap {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
</style>
